import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AppPermissions, Features } from "../../utils/consts";
import { Icon } from "../../utils/components";
import { useCompanyFeatureCheck, usePermissionCheck } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";
import SendCheersRightPanel from "pages/employeeCard/employee-achievements/SendCheersRightPanel";
import AddEditTimeOffRequest from "pages/employeeCard/time-off-profile/addEditTimeOffRequest/AddEditTimeOffRequest";
import AddBadgePanel from "pages/manage-badges/AddBadgePanel";

// we repeat gradient every 2 min (60000 milliseconds = 1 minute)
const repeatGradientTime = 60000 * 2;
const gradientAnimationDuration = 3000;

type MagicButtonProps = {
  additionalAction?: () => void;
};

const MagicButton: FC<MagicButtonProps> = ({ additionalAction }) => {
  const navigate = useNavigate();
  const { t } = useTranslation([
    "layout",
    "employeeAchievements",
    "events",
    "manageBadges",
  ]);
  const [isMagicOptionsVisible, setIsMagicOptionsVisible] = useState(false);
  const [isSendCheersModalOpen, setIsSendCheersModalOpen] = useState(false);
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);
  const [isAddBadgeModalOpen, setIsAddBadgeModalOpen] = useState(false);
  const [isGradientActive, setIsGradientActive] = useState(false);

  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const isAchievementsAdmin = usePermissionCheck([
    AppPermissions.Achievements_CreateEditCompanyAchievements,
  ]);
  const isBadgesAdmin = usePermissionCheck([AppPermissions.AddRemoveBadges]);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setIsGradientActive(true);

      setTimeout(() => setIsGradientActive(false), gradientAnimationDuration);
    }, repeatGradientTime);

    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  const toggleMagicOptions = () => {
    setIsMagicOptionsVisible((prevState) => !prevState);
  };

  const magicOptions = [
    {
      iconKey: "#earnCoinsOption",
      title: t("earnCoins"),
      isVisible: isGamificationAvailable,
      onOptionClick: () => {
        toggleMagicOptions();

        if (additionalAction) {
          additionalAction();
        }

        navigate("/achievements");
      },
    },
    {
      iconKey: "#BadgeMessageIcon",
      title: t("employeeAchievements:sendFeedbackTitle"),
      isVisible: true,
      onOptionClick: () => {
        toggleMagicOptions();
        setIsSendCheersModalOpen(true);
      },
    },
    {
      iconKey: "#requestTimeOff",
      title: t("requestTimeOff"),
      isVisible: useCompanyFeatureCheck(Features.TimeOff),
      onOptionClick: () => {
        toggleMagicOptions();
        setIsShowRequestModal(true);
      },
    },
    {
      iconKey: "#calendar",
      title: t("events:addNewAchievements"),
      isVisible: isGamificationAvailable && isAchievementsAdmin,
      onOptionClick: () => {
        toggleMagicOptions();

        if (additionalAction) {
          additionalAction();
        }

        navigate(`/achievements/add-achievement`, {
          state: { fromAchievementsList: true },
        });
      },
    },
    {
      iconKey: "#addBadge",
      title: t("manageBadges:action_addNewBadge"),
      isVisible: isBadgesAdmin,
      onOptionClick: () => {
        toggleMagicOptions();
        setIsAddBadgeModalOpen(true);
      },
    },
  ];

  return (
    <div className="magicButtonWrap">
      <button
        className={classNames("magicButton", {
          active: isMagicOptionsVisible,
          gradient: isGradientActive,
        })}
        onClick={toggleMagicOptions}
      >
        <div className="d-flex iconsWrap">
          <Icon href="#magicStar" svgClass="magicStar" />
          <Icon href="#magicUnion" svgClass="magicUnion" />
          <Icon href="#magicStar" svgClass="magicStar" />

          <span className="magicText">{t("magicBtn")}</span>
        </div>
      </button>

      <div
        className={classNames("magicButton__content", {
          fadeInUpMagicOptions: isMagicOptionsVisible,
        })}
      >
        {magicOptions.map(
          ({ title, iconKey, isVisible, onOptionClick }) =>
            isVisible && (
              <button
                key={iconKey}
                className="magicOption"
                onClick={onOptionClick}
              >
                <Icon href={iconKey} svgClass="magicOptionIcon" />
                <span>{title}</span>
              </button>
            )
        )}
      </div>

      <SendCheersRightPanel
        isModalOpen={isSendCheersModalOpen}
        onCloseModal={() => setIsSendCheersModalOpen(false)}
      />

      <AddEditTimeOffRequest
        isModalOpen={isShowRequestModal}
        onCloseModal={() => setIsShowRequestModal(false)}
      />

      <AddBadgePanel
        isModalOpen={isAddBadgeModalOpen}
        onCloseModal={() => setIsAddBadgeModalOpen(false)}
      />
    </div>
  );
};

export default MagicButton;
