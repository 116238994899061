import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AchievementsSortBy,
  AchievementsStatuses,
} from "api/types/achievementEventsTypes";
import { SortDirections } from "api/types/commonTypes";
import {
  AchievementCategoryItemDto,
  AchievementDto,
  AchievementParticipantsTabs,
} from "../../api/types/achievementTypes";
import { EmojiReactionUsersItemDto } from "../../api/types/emojiTypes";
import {
  UserOfSearch,
  UserOfSearchWithActiveStatus,
} from "../../api/types/usersTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { AchievementsState } from "../types";
import { logoutUserAction } from "./authReducer";

const initialState: AchievementsState = {
  achievementsCategories: defaultListState,
  achievementCategory: {
    categoryData: defaultListState,
    categoryAchievements: defaultStateWithInfiniteLoading,
    sortingAchievements: {
      sortBy: AchievementsSortBy.сreatedAt,
      sortDirection: SortDirections.desc,
    },
    status: "",
  },
  achievementPage: {
    achievementDetails: defaultListState,
    achievementApprovedParticipants: {
      topParticipants: defaultListState,
      totalCount: null,
    },
    achievementParticipants: {
      mode: AchievementParticipantsTabs.all,
      participantsList: defaultStateWithInfiniteLoading,
    },
    achievementReactions: defaultListState,
  },
  achievementsPage: {
    achievements: defaultStateWithInfiniteLoading,
    filterCategories: defaultListState,
    sortingAchievements: {
      sortBy: AchievementsSortBy.сreatedAt,
      sortDirection: SortDirections.desc,
    },
    status: "",
  },
};

const eventsSlice = createSlice({
  name: "achievements",
  initialState,
  reducers: {
    // list of achievements categories
    setLoadingAchievementsCategories: (state) => ({
      ...state,
      achievementsCategories: setStateLoading(state.achievementsCategories),
    }),
    setAchievementsCategories: (
      state,
      action: PayloadAction<AchievementCategoryItemDto[] | null>
    ) => ({
      ...state,
      achievementsCategories: setStateData(
        state.achievementsCategories,
        action.payload
      ),
    }),
    setErrorAchievementsCategories: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementsCategories: setStateError(
        state.achievementsCategories,
        action.payload
      ),
    }),
    resetAchievementsCategories: (state) => ({
      ...state,
      achievementsCategories: defaultListState,
    }),

    // achievements category page
    setLoadingAchievementCategory: (state) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryData: setStateLoading(state.achievementCategory.categoryData),
      },
    }),
    setAchievementCategory: (
      state,
      action: PayloadAction<AchievementCategoryItemDto>
    ) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryData: setStateData(
          state.achievementCategory.categoryData,
          action.payload
        ),
      },
    }),
    setErrorAchievementCategory: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryData: setStateError(
          state.achievementCategory.categoryData,
          action.payload
        ),
      },
    }),
    resetAchievementCategory: (state) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryData: defaultListState,
      },
    }),
    setLoadingCategoryAchievements: (state) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryAchievements: setInfiniteLoadLoading(
          state.achievementCategory.categoryAchievements
        ),
      },
    }),
    setCategoryAchievements: (
      state,
      action: PayloadAction<{
        achievements: AchievementDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let achievementsList =
        state.achievementCategory.categoryAchievements.data || [];
      achievementsList = [...achievementsList, ...action.payload.achievements];

      return {
        ...state,
        achievementCategory: {
          ...state.achievementCategory,
          categoryAchievements: setInfiniteLoadData(
            state.achievementCategory.categoryAchievements,
            achievementsList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorCategoryAchievements: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryAchievements: setInfiniteLoadStateErrorMessage(
          state.achievementCategory.categoryAchievements,
          action.payload
        ),
      },
    }),
    setCategoryAchievementsSorting: (
      state,
      action: PayloadAction<[AchievementsSortBy, SortDirections | string]>
    ) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        sortingAchievements: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setCategoryAchievementsStatus: (
      state,
      action: PayloadAction<AchievementsStatuses | string>
    ) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        status: action.payload,
      },
    }),
    resetCategoryAchievements: (state) => ({
      ...state,
      achievementCategory: {
        ...state.achievementCategory,
        categoryAchievements: defaultStateWithInfiniteLoading,
      },
    }),

    // Achievement Page
    setLoadingAchievementDetails: (state) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementDetails: setStateLoading(
          state.achievementPage.achievementDetails
        ),
      },
    }),
    setAchievementDetails: (state, action: PayloadAction<AchievementDto>) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementDetails: setStateData(
          state.achievementPage.achievementDetails,
          action.payload
        ),
      },
    }),
    setAchievementDetailsError: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementDetails: setStateError(
          state.achievementPage.achievementDetails,
          action.payload
        ),
      },
    }),
    setLoadingAchievementParticipants: (state) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementParticipants: {
          ...state.achievementPage.achievementParticipants,
          participantsList: setInfiniteLoadLoading(
            state.achievementPage.achievementParticipants.participantsList
          ),
        },
      },
    }),
    setAchievementParticipants: (
      state,
      action: PayloadAction<{
        participants: UserOfSearchWithActiveStatus[];
        page: number;
        hasMoreData: boolean;
      }>
    ) => {
      let participantsArr =
        state.achievementPage.achievementParticipants.participantsList.data ||
        [];
      participantsArr = [...participantsArr, ...action.payload.participants];

      return {
        ...state,
        achievementPage: {
          ...state.achievementPage,
          achievementParticipants: {
            ...state.achievementPage.achievementParticipants,
            participantsList: setInfiniteLoadData(
              state.achievementPage.achievementParticipants.participantsList,
              participantsArr,
              action.payload.hasMoreData,
              action.payload.page
            ),
          },
        },
      };
    },
    setAchievementParticipantsError: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementParticipants: {
          ...state.achievementPage.achievementParticipants,
          participantsList: setInfiniteLoadStateErrorMessage(
            state.achievementPage.achievementParticipants.participantsList,
            action.payload
          ),
        },
      },
    }),
    setAchievementParticipantsMode: (
      state,
      action: PayloadAction<AchievementParticipantsTabs>
    ) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementParticipants: {
          mode: action.payload,
          participantsList: defaultStateWithInfiniteLoading,
        },
      },
    }),
    setLoadingAchievementApprovedParticipants: (state) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementApprovedParticipants: {
          ...state.achievementPage.achievementApprovedParticipants,
          topParticipants: setStateLoading(
            state.achievementPage.achievementApprovedParticipants
              .topParticipants
          ),
        },
      },
    }),
    setAchievementApprovedParticipants: (
      state,
      action: PayloadAction<{
        participants: UserOfSearch[];
        totalParticipantsCount: number | null;
      }>
    ) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementApprovedParticipants: {
          topParticipants: setStateData(
            state.achievementPage.achievementApprovedParticipants
              .topParticipants,
            action.payload.participants
          ),
          totalCount: action.payload.totalParticipantsCount,
        },
      },
    }),
    setErrorAchievementApprovedParticipants: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementApprovedParticipants: {
          ...state.achievementPage.achievementApprovedParticipants,
          topParticipants: setStateError(
            state.achievementPage.achievementApprovedParticipants
              .topParticipants,
            action.payload
          ),
        },
      },
    }),
    // Achievement Reactions
    setLoadingAchievementReactions: (state) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementReactions: setStateLoading(
          state.achievementPage.achievementReactions
        ),
      },
    }),
    setAchievementReactions: (
      state,
      action: PayloadAction<EmojiReactionUsersItemDto[]>
    ) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementReactions: setStateData(
          state.achievementPage.achievementReactions,
          action.payload
        ),
      },
    }),
    setAchievementReactionsError: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementPage: {
        ...state.achievementPage,
        achievementReactions: setStateError(
          state.achievementPage.achievementReactions,
          action.payload
        ),
      },
    }),
    // list of achievements
    setLoadingAchievements: (state) => ({
      ...state,
      achievementsPage: {
        ...state.achievementsPage,
        achievements: setInfiniteLoadLoading(
          state.achievementsPage.achievements
        ),
      },
    }),
    setAchievements: (
      state,
      action: PayloadAction<{
        achievements: AchievementDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let achievementsList = state.achievementsPage.achievements.data || [];
      achievementsList = [...achievementsList, ...action.payload.achievements];

      return {
        ...state,
        achievementsPage: {
          ...state.achievementsPage,
          achievements: setInfiniteLoadData(
            state.achievementsPage.achievements,
            achievementsList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorAchievements: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementsPage: {
        ...state.achievementsPage,
        achievements: setInfiniteLoadStateErrorMessage(
          state.achievementsPage.achievements,
          action.payload
        ),
      },
    }),
    setFilterAchievementCategory: (
      state,
      action: PayloadAction<{ id: number; name: string }>
    ) => {
      const { data } = state.achievementsPage.filterCategories;

      if (!data?.some((category) => category.id === action.payload.id)) {
        const updatedCategories = data
          ? [...data, action.payload]
          : [action.payload];

        return {
          ...state,
          achievementsPage: {
            ...state.achievementsPage,
            filterCategories: setStateData(
              state.achievementsPage.filterCategories,
              updatedCategories
            ),
          },
        };
      }

      return state;
    },
    removeFilterAchievementCategory: (state, action: PayloadAction<number>) => {
      const { data } = state.achievementsPage.filterCategories;
      const updatedCategories =
        data?.filter((category) => category.id !== action.payload) || [];
      return {
        ...state,
        achievementsPage: {
          ...state.achievementsPage,
          filterCategories: setStateData(
            state.achievementsPage.filterCategories,
            updatedCategories
          ),
        },
      };
    },
    setAchievementsStatus: (
      state,
      action: PayloadAction<AchievementsStatuses | string>
    ) => ({
      ...state,
      achievementsPage: {
        ...state.achievementsPage,
        status: action.payload,
      },
    }),
    setAchievementstSorting: (
      state,
      action: PayloadAction<[AchievementsSortBy, SortDirections | string]>
    ) => ({
      ...state,
      achievementsPage: {
        ...state.achievementsPage,
        sortingAchievements: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    resetAchievements: (state) => ({
      ...state,
      achievementsPage: {
        ...state.achievementsPage,
        achievements: defaultStateWithInfiniteLoading,
      },
    }),
    resetEventsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default eventsSlice.reducer;

export const {
  setLoadingAchievementsCategories,
  setAchievementsCategories,
  setErrorAchievementsCategories,
  resetAchievementsCategories,
  resetEventsState,
  setLoadingAchievementCategory,
  setAchievementCategory,
  setErrorAchievementCategory,
  resetAchievementCategory,
  setLoadingCategoryAchievements,
  setCategoryAchievements,
  setErrorCategoryAchievements,
  setLoadingAchievementDetails,
  setAchievementDetails,
  setAchievementDetailsError,
  setLoadingAchievementParticipants,
  setAchievementParticipants,
  setAchievementParticipantsError,
  setAchievementParticipantsMode,
  setLoadingAchievementApprovedParticipants,
  setAchievementApprovedParticipants,
  setErrorAchievementApprovedParticipants,
  setLoadingAchievementReactions,
  setAchievementReactions,
  setAchievementReactionsError,
  setErrorAchievements,
  setAchievements,
  setLoadingAchievements,
  setFilterAchievementCategory,
  removeFilterAchievementCategory,
  setAchievementstSorting,
  setAchievementsStatus,
  setCategoryAchievementsStatus,
  setCategoryAchievementsSorting,
  resetCategoryAchievements,
  resetAchievements,
} = eventsSlice.actions;
