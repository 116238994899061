import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  BadgeGroupManagementDto,
  CreateBadgeGroupRequest,
  CreateBadgeGroupResponse,
  CreateBadgeResponse,
  EditBadgeGroupRequest,
  EditBadgeGroupResponse,
  GetBadgeGroupsManagementResponse,
  SetBadgeVisibilityRequest,
  SetBadgeVisibilityResponse,
  SetGroupVisibilityRequest,
  SetGroupVisibilityResponse,
} from "./types/badgesManagementTypes";

export const loadBadgeGroupsForManaging =
  (): AxiosPromise<GetBadgeGroupsManagementResponse> =>
    axiosInstance.get(`${base_url}/api/badge-groups/management`);

export const loadBadgeGroupsForManagingHost =
  (): AxiosPromise<GetBadgeGroupsManagementResponse> =>
    axiosInstance.get(`${base_url}/api/badge-groups/management/host`);

export const loadBadgeGroup = (
  badgeGroupId: number
): AxiosPromise<BadgeGroupManagementDto> =>
  axiosInstance.get(`${base_url}/api/badge-groups/management/${badgeGroupId}`);

export const createBadgeGroup = (
  body: CreateBadgeGroupRequest
): AxiosPromise<CreateBadgeGroupResponse> =>
  axiosInstance.post(`${base_url}/api/badge-groups/management`, body);

export const editBadgeGroup = (
  badgeGroupId: number,
  body: EditBadgeGroupRequest
): AxiosPromise<EditBadgeGroupResponse> =>
  axiosInstance.put(
    `${base_url}/api/badge-groups/management/${badgeGroupId}`,
    body
  );

export const deleteBadgeGroup = (groupId: number): AxiosPromise =>
  axiosInstance.delete(`${base_url}/api/badge-groups/management/${groupId}`);

export const createBadge = (
  body: FormData
): AxiosPromise<CreateBadgeResponse> =>
  axiosInstance.post(`${base_url}/api/badges/management`, body);

export const deleteBadge = (badgeId: number): AxiosPromise =>
  axiosInstance.delete(`${base_url}/api/badges/management/${badgeId}`);

export const changeBadgeVisibility = (
  badgeId: number,
  body: SetBadgeVisibilityRequest
): AxiosPromise<SetBadgeVisibilityResponse> =>
  axiosInstance.post(
    `${base_url}/api/badges/management/${badgeId}/set-visibility`,
    body
  );

export const changeGroupVisibility = (
  groupId: number,
  body: SetGroupVisibilityRequest
): AxiosPromise<SetGroupVisibilityResponse> =>
  axiosInstance.post(
    `${base_url}/api/badge-groups/management/${groupId}/set-visibility`,
    body
  );
