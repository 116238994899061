import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch } from "store";
import { Profile } from "../../api/types/profile";
import { UserProfile } from "../../api/types/usersTypes";
import { logoutUser } from "../../store/thunks/authThunks";
import Icon from "../../utils/components/Icon";
import { getAvatarSign } from "../../utils/methods";
import SidebarLinks from "./SidebarLinks";
import { getCropTitle } from "utils/methods/getCropTitle";
import { EsteemePolicies } from "../../appConsts";
import UserAvatar from "../../utils/components/UserAvatar";
import MagicButton from "./MagicButton";

type SidebarProps = {
  isSidebarVisible: boolean;
  closeSidebar: () => void;
  userProfile: Profile;
  userInfo: Partial<UserProfile>;
  counter: number | string;
};

const Sidebar: FC<SidebarProps> = ({
  isSidebarVisible,
  closeSidebar,
  userProfile,
  userInfo,
  counter,
}) => {
  const { t } = useTranslation("layout");
  const { t: tCommon } = useTranslation("common");
  const dispatch = useAppDispatch();

  const name = userInfo.firstName ? `${userInfo.firstName}` : "...";
  const surname = userInfo.familyName ? `${userInfo.familyName}` : "";
  const avatarSign = getAvatarSign(
    userInfo?.firstName,
    userInfo?.familyName,
    ""
  );
  const companyLogoUrl = userProfile.companyLogoImageUrl
    ? userProfile.companyLogoImageUrl
    : "/img/logo.png";

  return (
    <aside
      className={classNames("sidebar", {
        active: isSidebarVisible,
      })}
    >
      <div>
        <Link to="/" className="logo">
          <img src={companyLogoUrl} alt="logo" draggable={false} />
        </Link>

        <div className="sidebar__control d-flex" onClick={closeSidebar}>
          <Icon href="#close-sidebar" svgClass="close-sidebar" />

          <Link to="/merch-store" className="header__count">
            <Icon href="#coins" svgClass="ic-coins" />
            {counter}
          </Link>
        </div>
        <Link to={`/employee/${userInfo?.id}`} onClick={closeSidebar}>
          <div className="user">
            <UserAvatar
              isOnline
              avatarSign={avatarSign}
              avatarImageUrl={userInfo.avatarImageUrl}
            />
            <div>
              <div className="userInfo">
                <p className="user-name">{getCropTitle(name, 15)}</p>
                <p className="user-name">{getCropTitle(surname, 15)}</p>
              </div>
              <span className="profileLink">{tCommon("viewProfile")}</span>
            </div>
          </div>
        </Link>

        <MagicButton additionalAction={closeSidebar} />

        <SidebarLinks closeSidebar={closeSidebar} />
      </div>

      <div>
        <div
          className="menu__link logout-btn"
          onClick={async () => {
            await dispatch(logoutUser());
            window.location.href = "/login";
          }}
        >
          <div className="menu__itemContent">
            <Icon href="#logout" svgClass="menu__icon" />
            <span className="menu__linkName">{t("navigation_logout")}</span>
          </div>
        </div>

        <div className="sidebar__policyLinkWrap">
          {[EsteemePolicies[0], EsteemePolicies[3]].map((policy, index) => (
            <a
              key={policy.linkId}
              href={policy.href}
              target="_blank"
              className="sidebar__policyLink"
              rel="noreferrer"
            >
              {t(`${policy.linkId}_link`)}
            </a>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
