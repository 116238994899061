import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { loadBadgeGroupsForManaging } from "../../api/badgesManagementApi";
import { getErrorMessage } from "../../utils/methods";
import {
  resetBadgeGroupsList,
  setBadgeGroups,
  setErrorBadgeGroups,
  setLoadingBadgeGroups,
} from "../reducers/manageBadgesReducer";

export const getBadgeGroupsForManagement =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingBadgeGroups());
    try {
      const response = await loadBadgeGroupsForManaging();
      dispatch(setBadgeGroups(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorBadgeGroups(errorMessage));
    }
  };

export const refreshBadgeGroupsList =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await Promise.all([
      dispatch(resetBadgeGroupsList()),
      dispatch(getBadgeGroupsForManagement()),
    ]);
  };
