import { AxiosError } from "axios";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  createBadge,
  loadBadgeGroupsForManaging,
} from "../../api/badgesManagementApi";
import {
  BadgeGroupManagementDto,
  NewBadgeRequestBody,
} from "../../api/types/badgesManagementTypes";
import { useAppDispatch, useAppSelector } from "../../store";
import { refreshBadgeGroupsList } from "../../store/thunks/manageBadgesThunk";
import { serverError } from "../../types/serverError";
import AggregateErrorMessage from "../../utils/components/AggregateErrorMessage";
import { getErrorMessage, handleServerError } from "../../utils/methods";
import LayoutRightPanel from "../layout/LayoutRightPanel";
import { ButtonLoader, Icon, LoaderSize } from "utils/components";
import classNames from "classnames";
import { toastError, toastSuccess } from "utils/toasts";
import Loader from "../../utils/components/Loader";
import CustomSelect from "../../utils/components/CustomSelect";
import { AppPermissions } from "../../utils/consts";
import { usePermissionCheck } from "../../utils/hooks";
import RequiredFieldLabel from "../../utils/components/RequiredFieldLabel";

type AddBadgePanelProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

type AddBadgeValues = {
  name: string;
  badgeImage: null | File;
  groupId: number | null;
  groupName: string | null;
};

const initialValues: AddBadgeValues = {
  name: "",
  badgeImage: null,
  groupId: null,
  groupName: null,
};

const AddBadgePanel: FC<AddBadgePanelProps> = ({
  isModalOpen,
  onCloseModal,
}) => {
  const { t } = useTranslation("manageBadges");
  const { t: tCommon } = useTranslation("common");
  const currentCompanyId = useAppSelector(
    (state) => state.auth.profile?.companyId
  );
  const isAdminForManageBadges = usePermissionCheck([
    AppPermissions.AddRemoveBadges,
  ]);

  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<AddBadgeValues>>(null);

  const [isBadgeGroupLoading, setIsBadgeGroupLoading] = useState(false);
  const [badgeGroups, setBadgeGroups] = useState<BadgeGroupManagementDto[]>([]);
  const [errorBadgeGroup, setErrorBadgeGroup] = useState("");
  const [showNewBadgeGroup, setShowNewBadgeGroup] = useState<boolean>(false);

  const badgeGroupOptions = badgeGroups.map((badgeGroup) => ({
    value: badgeGroup.id,
    label: badgeGroup.name,
  }));

  useEffect(() => {
    if (isModalOpen) {
      (async () => {
        setIsBadgeGroupLoading(true);

        try {
          const response = await loadBadgeGroupsForManaging();
          const badgesGroupsFromServer = response.data.items;
          const editableBadgeGroups = badgesGroupsFromServer.filter(
            (group) => group.companyId === currentCompanyId && !group.isSystem
          );
          setBadgeGroups(editableBadgeGroups);
        } catch (e) {
          const errorMessage = getErrorMessage(e as AxiosError);
          setErrorBadgeGroup(errorMessage);
        } finally {
          setIsBadgeGroupLoading(false);
        }
      })();
    }
  }, [isModalOpen]);
  const closeModal = () => {
    formikRef.current?.resetForm();
    formikRef.current?.setErrors({});
    setImagePreviewURL(null);
    setShowNewBadgeGroup(false);
    onCloseModal();
  };
  const [imagePreviewURL, setImagePreviewURL] = useState<string | null>(null);

  const handleSubmit = async (
    values: AddBadgeValues,
    { setErrors }: FormikHelpers<AddBadgeValues>
  ) => {
    const requestBody: NewBadgeRequestBody = {
      name: values.name,
      ...(values.groupId !== undefined ? { groupId: values.groupId } : {}),
      ...(values.groupName !== undefined
        ? { groupName: values.groupName }
        : {}),
    };

    const formData: FormData = new FormData();
    const body = new Blob([JSON.stringify(requestBody)], {
      type: "application/json",
    });
    formData.append("Body", body, "");
    if (values.badgeImage) {
      formData.append("BadgeImage", values.badgeImage);
    }

    try {
      await createBadge(formData);
      dispatch(refreshBadgeGroupsList());
      toastSuccess(t("badgeSuccessfullySaved", { badgeName: values.name }));
      closeModal();
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));

      const badgeErrors = handleServerError(error || {}, "Body");
      const badgeImageErrors = handleServerError(error || {}).badgeImage;

      const aggregateErrors: any = {
        ...badgeErrors,
        badgeImage: badgeImageErrors,
      };

      setErrors(aggregateErrors);
    }
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(t("badgeName_required") as string),
    badgeImage: Yup.mixed()
      .nullable()
      .required(t("badgeImage_required") as string),
    groupId: Yup.number().when({
      is: () => !showNewBadgeGroup,
      then: Yup.number()
        .nullable()
        .required(tCommon("validation_fieldIsRequired") as string),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    groupName: Yup.string().when([], {
      is: () => showNewBadgeGroup,
      then: Yup.string()
        .nullable()
        .required(tCommon("validation_fieldIsRequired") as string),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  });

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={t("createBadgeTitle")}
      additionalClassName="popup-small"
    >
      {isModalOpen ? (
        <>
          {isBadgeGroupLoading ? (
            <div className="loader-container">
              <Loader size={LoaderSize.Page} />
            </div>
          ) : (
            <>
              {errorBadgeGroup ? (
                <>
                  <div className="new-wrapper error">{errorBadgeGroup}</div>
                </>
              ) : (
                <>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={ValidationSchema}
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                  >
                    {(props: FormikProps<AddBadgeValues>) => {
                      const {
                        values,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                      } = props;

                      return (
                        <Form
                          className="form-style popup-panel-form addBadgeForm"
                          autoComplete="off"
                        >
                          <div className="new-wrap">
                            <AggregateErrorMessage
                              component="div"
                              className="error"
                            />
                            <div className="file-upload">
                              <div className="file-upload__image-block">
                                <div className="file-upload__image-preview">
                                  {imagePreviewURL && (
                                    <img
                                      src={imagePreviewURL}
                                      alt="badgeImage"
                                      className="file-upload__image"
                                    />
                                  )}
                                </div>
                              </div>
                              <label className="btn-regular">
                                <input
                                  name="badgeImage"
                                  type="file"
                                  accept=".png, .jpeg, .jpg, .gif, .svg"
                                  onChange={(event) => {
                                    if (event.target.files?.length) {
                                      const imageUrl = URL.createObjectURL(
                                        event.target.files[0]
                                      );
                                      setImagePreviewURL(imageUrl);
                                      setFieldValue(
                                        "badgeImage",
                                        event.target.files[0]
                                      );
                                    }
                                  }}
                                />
                                <Icon
                                  href="#fileUpload"
                                  svgClass="ic-file-upload"
                                />
                                <span className="file-upload__name">
                                  {values.badgeImage
                                    ? values.badgeImage.name
                                    : t("uploadBadge")}
                                </span>
                              </label>
                              <ErrorMessage
                                component="div"
                                className="error"
                                name="badgeImage"
                              />
                            </div>

                            <label className="label m-top">
                              <RequiredFieldLabel labelTitle={t("badgeName")} />
                              <Field
                                type="text"
                                name="name"
                                className="form-input"
                                placeholder={t("badgeName_placeholder")}
                              />
                              <ErrorMessage
                                component="div"
                                name="name"
                                className="error"
                              />
                            </label>

                            <div className="label m-top">
                              <RequiredFieldLabel
                                labelTitle={t("badgeGroup")}
                              />
                              {badgeGroups.length === 0 &&
                                !values.groupName && (
                                  <p className="error badgeGroupMsg">
                                    {isAdminForManageBadges
                                      ? tCommon("noBadgesToDisplay_admin")
                                      : tCommon("noBadgesToDisplay_user")}
                                  </p>
                                )}
                              {badgeGroups.length > 0 && (
                                <>
                                  <Field
                                    name="groupId"
                                    component={CustomSelect}
                                    isSearchable
                                    options={badgeGroupOptions}
                                    placeholder={tCommon("select_placeholder")}
                                    handleChange={() => {
                                      setFieldValue("groupName", "", false);
                                      setShowNewBadgeGroup(false);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="groupId"
                                    component="p"
                                    className="error"
                                  />
                                </>
                              )}
                            </div>

                            <button
                              type="button"
                              className={classNames("btn-additional", {
                                minusIcon: showNewBadgeGroup,
                              })}
                              onClick={() => {
                                const shouldShowNewBadgeGroup =
                                  !showNewBadgeGroup;
                                setShowNewBadgeGroup((prevState) => !prevState);

                                if (shouldShowNewBadgeGroup) {
                                  setFieldValue("groupId", null, false);
                                  setFieldError("groupId", undefined);
                                } else {
                                  setFieldValue("groupName", "");
                                }
                              }}
                            >
                              {t("createNewGroup")}
                            </button>

                            {showNewBadgeGroup ? (
                              <label className="label m-top">
                                <Field
                                  type="text"
                                  name="groupName"
                                  placeholder={
                                    t("addNewGroup_placeholder") as string
                                  }
                                  className="form-input"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="groupName"
                                  className="error"
                                />
                              </label>
                            ) : null}
                          </div>

                          <div className="popup-footer">
                            <div className="group-btn d-flex">
                              <button
                                className="btn-tertiary main-close"
                                type="button"
                                onClick={closeModal}
                              >
                                {tCommon("form_cancel")}
                              </button>

                              <button
                                type="submit"
                                className="btn-primary"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <ButtonLoader />
                                ) : (
                                  tCommon("form_save")
                                )}
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </>
              )}
            </>
          )}
        </>
      ) : null}
    </LayoutRightPanel>
  );
};

export default AddBadgePanel;
